// extracted by mini-css-extract-plugin
export var container = "abouthalva-module--container--jt-7h";
export var block = "abouthalva-module--block--P7tVS";
export var blockReverse = "abouthalva-module--blockReverse--D+ZlU";
export var text = "abouthalva-module--text--eQJMY";
export var title = "abouthalva-module--title--rgwAQ";
export var img = "abouthalva-module--img--fQNGQ";
export var imgMarginBot = "abouthalva-module--imgMarginBot--dP56m";
export var imgWrap = "abouthalva-module--imgWrap--vyfCO";
export var list = "abouthalva-module--list--sS9fm";
export var item = "abouthalva-module--item--7rAv5";
export var itemText = "abouthalva-module--itemText--8EY20";
export var btnWrap = "abouthalva-module--btnWrap--gNPXE";
export var btn = "abouthalva-module--btn--lAL64";