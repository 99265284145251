import React from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/Layout"
import { Banner } from "../components/b2b/Banner"
import { AboutHalva } from "../components/b2b/AboutHalva"
import { Card } from "../components/b2b/Card"
import { Footer } from "../components/b2b/Footer"
import { PageData } from "../interfaces/pageProps"
import { getPageData } from "../helpers/getPageData"

export default function Pageb2b({ data }: PageProps<PageData>) {
  const { seoData } = getPageData(data)
  return (
    <Layout seoData={seoData} hasCustomFooter>
      <Banner />
      <Card />
      <AboutHalva />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    pageDataJson(pageName: { eq: "b2b" }) {
      seoData {
        title
        description
      }
    }
  }
`
