import React from "react"
import Container from "@ecom/ui/components/Container"

import * as styles from "./footer.module.scss"

export const Footer = () => (
  <section className={styles.section}>
    <Container className={styles.container}>
      <div className={styles.left}>
        <div className={styles.phone}>8-800-100-777-2</div>
        <p>Для звонков по России</p>
      </div>

      <div className={styles.right}>
        2021 © ПАО «Совкомбанк». Генеральная лицензия Банка России №963. Кострома, пр.
        Текстильщиков, 46
      </div>
    </Container>
  </section>
)
