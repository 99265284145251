import React from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"
import Button from "@ecom/ui/components/Button"

import * as styles from "./abouthalva.module.scss"

import img_1 from "./img/1.svg"
import img_2 from "./img/2.svg"
import img_3 from "./img/3.svg"
import img_4 from "./img/4.svg"
import img_5 from "./img/5.svg"

export const AboutHalva = () => (
  <section>
    <Container className={styles.container}>
      <div className={styles.block}>
        <div className={styles.text}>
          <div className={styles.title}>Клиенты с Халвой покупают на 30% больше</div>
          <div className={styles.text}>
            Халва меняет поведение клиентов. Покупка в рассрочку – это возможность купить товар или
            услугу без переплаты
          </div>
          <div className={styles.list}>
            <li className={styles.item}>
              <div className={styles.itemText}>Клиенты покупают дополнительные товары</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Клиенту доступны более дорогие товары</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>
                Рассрочка – не кредит. Нет комиссий, нет процентов
              </div>
            </li>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img alt="human" className={styles.img} src={img_1} />
        </div>
      </div>

      <div className={clsx(styles.block, styles.blockReverse)}>
        <div className={styles.text}>
          <div className={styles.title}>Приведем клиентов, которые никогда у вас не покупали</div>
          <div className={styles.text}>
            Возможность купить в рассрочку – огромная ценность для массового покупателя
          </div>
          <div className={styles.list}>
            <li className={styles.item}>
              <div className={styles.itemText}>
                Халва приводит более 50% новых клиентов (на основании опроса партнеров)
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>
                Продвижение магазинов партнeров – наша ключевая задача
              </div>
            </li>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img alt="human" className={clsx(styles.img, styles.imgMarginBot)} src={img_2} />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.text}>
          <div className={styles.title}>Повысим лояльность к вашему бренду</div>
          <div className={styles.text}>
            Базовое правило умного шопинга – всегда проверять, является ли магазин партнeром Халвы
          </div>
          <div className={styles.list}>
            <li className={styles.item}>
              <div className={styles.itemText}>Халва возвращает ушедших клиентов</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Бесплатная рассрочка – только у партнeров Халвы</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Клиенты покупают там, где есть рассрочка</div>
            </li>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img alt="human" className={styles.img} src={img_3} />
        </div>
      </div>

      <div className={clsx(styles.block, styles.blockReverse)}>
        <div className={styles.text}>
          <div className={styles.title}>Клиенты с Халвой – всегда с деньгами</div>
          <div className={styles.text}>Когда у клиента нет денег – он не покупает</div>
          <div className={styles.list}>
            <li className={styles.item}>
              <div className={styles.itemText}>
                У наших клиентов есть 200 млрд рублей на покупки
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Халва – карта первого выбора в кошельке клиента</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Увеличиваем количество покупателей каждый день</div>
            </li>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img alt="human" className={styles.img} src={img_4} />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.text}>
          <div className={styles.title}>Продавайте товары по всей стране</div>
          <div className={styles.text}>
            Халва – крупнейшая программа лояльности, в которой участвует более 7 млн клиентов
          </div>
          <div className={styles.list}>
            <li className={styles.item}>
              <div className={styles.itemText}>Помогаем развивать онлайн-продажи</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Поддержим вашу экспансию в другие города</div>
            </li>
            <li className={styles.item}>
              <div className={styles.itemText}>Гарантируем эффект везде, где есть Халва</div>
            </li>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img alt="human" className={clsx(styles.img, styles.imgMarginBot)} src={img_5} />
        </div>
      </div>
      <div className={styles.btnWrap}>
        <Button className={styles.btn} href="https://halvaclub.ru/b2b">
          стать партнeром
        </Button>
      </div>
    </Container>
  </section>
)
