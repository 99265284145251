import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@ecom/ui/components/Button"
import { Img } from "./Img"
import * as styles from "./banner.module.scss"

export const Banner = () => (
  <section>
    <Container className={styles.container}>
      <Img alt="bg img" className={styles.img} />
      <div className={styles.textBlock}>
        <h2 className={styles.title}>Национальная система рассрочек «Халва»</h2>
        <div className={styles.subTitle}>Продавайте больше и экономьте на маркетинге</div>
        <Button className={styles.btn} href="https://halvaclub.ru/b2b">
          стать партнeром
        </Button>
      </div>
    </Container>
  </section>
)
