import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    img: file(relativePath: { eq: "b2b/card.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export function Img(props: Omit<GatsbyImageProps, "image">) {
  const data = useStaticQuery(imgQuery)
  return <GatsbyImage {...props} image={getImage(data.img)!} />
}
